.greeting {
    display: flex;
    background: url(/public/insley.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top left;
    background-size: cover;
    height: 100%;
    flex-direction: column;
    max-width: 100vw;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}
.hom{
    display: flex;
    width: 100%;
}
.home__left{
    flex: 0.5;
    width: 100%;
}
.home__left >img{
    width: 0%;
    height: 0%;
}
.home__right{
    padding-top: 100px;
    padding-bottom: 100px;
    flex: 0.5;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.greeting__msg {
    color: #f5f5f5;
    font-size: 5vw;
    word-wrap: break-word;
    font-weight: 350;
    margin-top: 20px;
    margin-bottom: 50px;
}
.home__quote{
    margin-right: 20px;
    text-align: center;
    font-size:35px;
    font-weight: 300;
    line-height: 1.5;
    color: #f5f5f5;
}
.home__quote > span{
    color: grey;
    font-size: 18px;
    float: right;
    margin-right: 15px;
}
.greeting__promise {
    font-size: 3.5vw;
    font-weight: 300;
    margin: 20px;
}
.greet__container__box{
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 250px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.greet__container__img>img{
    width: 250px;
    border-radius: 5px;
    height: fit-content;
}
.greet__container__box > p{
    color:#f5f5f5;
    position: fix;
}
.greeting__btn{
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 80px;
    border-radius: 7px;
    font-size: 35px;
    font-weight: 600;
    color:#f0fff0;
    border: 3px solid #ffff00;
    background: rgba(0, 0, 0, 0.8);
  }
  .greeting__btn:hover{
    background: rgba(0, 0, 0, 0.8);
    color: #ffff00;
    -webkit-transition: color 1s ease-in-out;
    -moz-transition: color 1s ease-in-out;
    -o-transition: color 1s ease-in-out;
    transition: color 1s ease-in-out;
    cursor:pointer;
  }
  .greeting__btn:focus{
      outline: none;
  }
  @media screen and (max-width: 800px){
      .hom{
          flex-direction: column;
      }
      .home__left >img{
          height: auto;
          width: 100%;
          background-position: center top left;
          background-size: cover;
      }
      .greeting__btn{
          font-size: 30px;
      }
      .home__quote{
        font-size: 25px;
      }
      .home__right{
          padding-top: 20px;
          padding-bottom: 20px;
      }
      .greeting__btn{
          margin-top:30px;
      }
  }

  @media screen and (max-width: 450px){
      .greeting{
          background: #111;
      }
    .greeting >h1{
        color: white;
    }
    .greeting__msg{
        font-size: 30px;
    }
    .greeting__promise{
        font-size: 22px;
        color: white;
       
    }
    .services__msg{
        font-size: 22px;
        font-weight: 400;
    }
    .greeting__btn{
        font-size: 20px;
    }
  }
