.popup{
    width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, .15);
	backdrop-filter: blur(8px);
	display: flex;
	justify-content: center;
	align-items:center;
	position: fixed;
	z-index: 100000;
}
.popup__container{
    width: 1200px;
    height: 630px;
	display: flex;
    flex-wrap: wrap;
    background-color: #080808;
}
.popup__img{
    display: flex;
}
.popup__cross__btn{
    position: absolute;
    right: 11%;
    top: 9%;
}
.cross__icon{
    color: #FFFF00 !important;
    font-size: 50px !important;
    border: 2px solid #FFFF00;
    border-radius: 6px;
}
.popup__data{
    background-color: #080808;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}
.popup__data h2{
    margin-top: 50px;
    font-family: stencil;
	font-size: 50px;
    text-transform: uppercase;
    color: #FFFF00;
	font-weight: 400;
}
.popup__data h4{
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
}
.contact{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}
.contact__input{
	margin: 5px;
    height: 20px;
	width: 440px;
    padding: 10px 20px;
    border-radius: 5px;
   outline: none;
   border: 2px solid #f5f5f5;
   cursor: pointer;
   color: #FFEA00;
	background-color: transparent;
}
.contact__btn{ 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin: 30px;
    font-size: 25px;
    font-weight: 400;
    text-justify: center;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
    padding: 15px;
    height: 50px;
    text-align:center;
    border: 2px solid #f5f5f5;
    border-radius: 5px;
  }
  @media screen and (max-width: 1124px){
    .popup__data{
        background-image: url('./insleyJourney.webp');
        background-repeat: no-repeat;
        height: 100%;
    }
    .popup__container{
        flex-direction: column;
       
    }
    .popup__img >img{
        width: 0px;
        height: 0px;
    }
    .popup__data >h2{
        font-size: 30px;
    }
    .popup__data >h4{
        font-size: 18px;
    }
    .cross__icon{
        font-size:30px !important;
    }
    .contact__input{
        width: 250px;
    }
    .contact__btn{
        width: 120px;
        font-size: 16px;
    }
  }
  @media screen and (max-width: 450px){
    .contact__input{
        width: 200px;
    }
  }