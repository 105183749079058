.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.about__slider{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: 750px;  
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.about__us{
    display: flex;
    flex-direction: column;
}
.about__about{
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color:  #222;
    color: #f5f5f5;
}
.h4{
    font-size:40px;
    font-weight: 500;
    margin-top: 20px;
}
.custom{
  background-color: #ffff00;
  font-size:40px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  text-align:center;
  padding-top:10px;
}
.p{
    word-wrap: break-word;
    padding: 60px;
    word-spacing: 2px;
    font-size: 25px;
    align-items: center;
    text-align: justify;;
    color: #999;
}
.vm{
  color:#222;
}
.br{
    margin: 5px;
}
.about__vision{
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color:  yellow;
    color: #f5f5f5;
}
.about__vision >h4{
    color: #000;
}
.about__values{
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color:  #222;
    color: #f5f5f5;
}
 /* about all team */
 .about__team{
     display: flex;
     flex-wrap: wrap;
     background: #ffff00;
     width: 100%;
     justify-content: space-evenly;
 }
 .container{
    position: relative;
    margin: 20px;
  }
  
  .container .card{
    position: relative;
    width: 320px;
    height: 450px;
    background: #232323;
    border-radius: 20px;
    overflow: hidden;
  }
  
  .container .card:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffbf00;
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
  }
  
  .container .card:hover:before{
    clip-path: circle(300px at 80% -20%);
    background: #ffbf00;
  }
  
  .container .card:after{
    content: '';
    position: absolute;
    top: 30%;
    left: -12%;
    font-size: 9em;
    font-weight: 800;
    font-style: italic;
    color: rgba(255,255,25,0.05)
  }
  
  .container .card .imgBx{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 100%;
    height: 220px;
    transition: 0.5s;
  }
  
  .container .card:hover .imgBx{
    top: 0%;
    transform: translateY(0%);
    opacity: 0.9;
      
  }
  
  .container .card .imgBx img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
  }
  
  .container .card .contentBx{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    transition: 1s;
    z-index: 10;
  }
  
  .container .card:hover .contentBx{
    height: 210px;
  }
  
  .container .card .contentBx h2{
    position: relative;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #fff;
    margin: 0;
  }
  
  .container .card .contentBx .social__icons {
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .container .card:hover .contentBx .social__icons{
    opacity: 1;
    visibility: visible;
    transition-delay: 0.5s;
  }
  .container .card .contentBx .social__icons a{
    display: inline-block;
    margin: 0 5px;
    transition: 0.5s;
    cursor: pointer;
  }
  .social__icon{
      color: #f5f5f5 !important;
      border-radius: 50%;
      padding: 10px;
  }
  .social__icon:hover{
        color: #111 !important;
        background-color: #f5f5f5 !important;
  }
  .container .card .contentBx p{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 400;
    color: #f5f5f5;
    opacity: 0;
    transform: translateY(50px);
    transition: 0.5s;
    margin-top: 0;
  }
  
  .container .card:hover .contentBx p{
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 0.75s;
    
  }
  
  .designation{
    color: grey;
    font-size: 15px;
    font-weight: 400;
  }
  .container .card:hover .designation{
    font-size:0px;
  }

@media screen and (max-width: 450px){
    .h4{
        font-size:30px;
    }
    .p{
        font-size:18px;
        padding: 7px;
    }
}