/*Navbar*/
.navbar{
    display: flex;
    justify-content: space-between;
    position: fixed;
    max-width: 100vw;
    width: 100%;
    top: 0;
    z-index: 100;
    /* padding-left: 20px;
    padding-right: 20px; */
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    align-items: center;
}
.brand__logo{
    width: 90px;
    height: 40px;
    object-fit: contain;
    margin-left: 10px;
}
.active{
    background-color: #212121;
}

.active__insta{
    color: #fff !important;
}
.brand{
    flex: .3;
}
.brand__link{
    text-decoration: none;
    text-align: center;
}

.navbar__links{
    display: flex;
    flex: .5;
    align-items: center;
    justify-content: space-around;
}
.insta__icon{
    color: #f5f5f5;
    font-size: 27px !important;
    padding: 5px;
}
.insta__icon:hover{
    color: #ffff00 !important;
    cursor: pointer;
    padding: 5px;
    -webkit-transition: color 1s ease-in-out;
    -moz-transition: color 1s ease-in-out;
    -o-transition: color 1s ease-in-out;
    transition: color 1s ease-in-out;
}
.active__insta:hover{
    border-radius: 50%;
    color: #ffff00 !important;
    cursor: pointer;
    padding: 5px;
    -webkit-transition: color 1s ease-in-out;
    -moz-transition: color 1s ease-in-out;
    -o-transition: color 1s ease-in-out;
    transition: color 1s ease-in-out;
}


/* Single Nav*/
.singlenav__link{
 margin-left: 5px;
 text-decoration: none;
}
.singlenav{
    display: flex;
    margin-left: 10px;
    align-items: center;
}
.singlenav:hover > *{
    cursor: pointer;
    color: #ffff00!important;
}
.singlenav__text{
    color: #f5f5f5;
    font-size: 18px;
    font-weight: 400;
}
.singlenav__icon{
    color: #f5f5f5 !important;
    font-size: 24px !important;
    margin-right: 2px;
}
.active__nav{
    color: white !important;
}
/* media responsive */
@media screen and (max-width: 800px){
    .navbar{
        background-color: #212121;
    }
    .brand__text{
        color: #fff; 
    }
    .insta__icon{
        color: #fff !important;
    }
    .singlenav__text{
        font-size: 0;
    }
    .singlenav__icon{
        padding: 5px;
        color: #fff !important;
    }
    .singlenav__icon:hover{
        color: #ffff00 !important;
        padding: 5px;
        cursor: pointer;
        -webkit-transition: background-color 1s ease-in-out;
        -moz-transition: background-color 1s ease-in-out;
        -o-transition: background-color 1s ease-in-out;
        transition: background-color 1s ease-in-out;
    
    }
    .brand__text{
        font-size: 24px;
    }
}
@media screen and (max-width: 450px){
    .brand__logo{
        width:60px;
        height: 30px;
    }
}
@media screen and (max-width: 360px){
    .brand{
        flex: 0;
    }
   
    .navbar__links{
        flex: 1;
    }

    .brand__text{
        font-size: 0px;
    }
}