.services {
    text-align: center;
    background-color: #222;
}
.services__msg {
    margin: 40px;
    color: #f5f5f5;
    font-size: 60px;
    font-weight: 350;
}
.services__container {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.services__container__box {
    flex-direction: column;
    width: 250px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 4px 0px #ffff00;
    padding: 30px;
    margin: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.services__container__box:hover{
    box-shadow: 0 0 25px 8px #ffff00;
    background-color: #f0fff0;
    transition: ease-in-out;
}


.services__container__box h2 {
    color: #fff;
}

.services__container__box:hover h2{
    color: #004d40;
    font-weight: 1000;
}
.services__container__box p {
    margin-top: 10px;
    color: #777;
}

.services__container__box:hover p {
    font-weight: 800;
}

.services__life {
    background: yellow;
    padding: 60px;
}

.services__life h2 {
    font-size: 3vw;
}

.services__life p {
    margin-top: 40px;
    font-size: 1.3vw;
    line-height: 1.8;
}

.services__work {
    padding: 60px;
    background-color: #222;
}

.services__work h2 {
    font-size: 3vw;
    color: #f5f5f5;
}

.services__work h3 {
    margin-top: 20px;
    text-align: left;
    font-size: 2vw;
    color: #f9f9f9;
}

.services__work p {
    margin-top: 10px;
    text-align: justify;
    font-size: 1.3vw;
    line-height: 1.8;
    color: #999;
}
@media screen and (max-width: 1100px){
    .services__life, .services__work{
        padding: 20px;
    }
    .services__container__box{
        padding:15px;
    }
    .services__life h2{
        font-size: 40px;
    }
    .services__life p{
        margin-top: 25px;
        font-size: 18px;
        text-align: justify;
    }
    .services__work p{
        font-size: 18px;
        text-align: justify;
    }
    .services__work h2{
        font-size: 40px;
    }
    .services__work h3{
        font-size: 28px;
    }
    .services__msg{
        font-size:30px;
    }
}
@media screen and (max-width: 429px){
    .services__life h2,.services__work h2{
        font-size: 30px;
    }
    .services__work h3{
        font-size:23px;
    }
    .services__msg{
        font-size:28px;
    }
}
@media screen and (max-width: 415px){
    .services__life h2,.services__work h2{
        font-size: 28px;

    }
    .services__work h3{
        font-size:22px;
    }
    .services__msg{
        font-size:25px;
    }
}
@media screen and (max-width: 400px){
    .services__life, .services__work{
        padding: 10px;
    }
    .services__life h2,.services__work h2{
        font-size: 30px;
    }
    .services__life p{
        margin-top:15px
    }
    .services__life p,.services__work p{
        font-size: 15px;
    }
    .services__work h3{
        font-size:22px;
    }
}