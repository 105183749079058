.footer {
    background: #111;
    text-align: center;
    color: white;
}
.footer__whatsApp {
    align-items: center;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.footer__whatsApp p {
    padding: 20px;
    flex-direction: column;
    font-size: 50px;
    letter-spacing: 2px;
}
.footer__whatsApp img {
    background: yellow;
    padding: 12px;
    flex-direction: column;
    width: 200px;
}
.footer__address {
    background: none;
}
.footer h1 {
    font-size: 50px;
    color: yellow;
    padding: 40px;
}
.footer__address__details {
    align-items: center;
    display: flex;
    padding: 40px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.footer__address__details__box {
    flex-direction: column;
    width: 280px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    padding: 30px;
    margin: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.footer__address__details__box:hover{
    box-shadow: 0 0 25px 8px #d0d0d0;
    background-color: #f0fff0;
    transition: ease-in-out;
}
.footer__address__details__box p {
    margin-top: 10px;
    color: #777;
    font-weight: 300;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
}
.footer__address__details__box:hover h2{
    color: #004d40;
    font-weight: 1000;
}
.footer__address__details__box:hover p{
    font-weight: 800;
}
.footer__address h3 {
    padding: 30px;
    color: #666;
}
@media screen and (max-width: 1070px){
    .footer__whatsApp img{
        margin-top: 25px;
        width: 150px;
    }
    .footer__whatsApp p{
        font-size:35px;
    }
}
@media screen and (max-width: 740px){
    .footer__whatsApp p{
        font-size:22px;
    }
}
@media screen and (max-width: 600px){
    .footer h1 {
        font-size: 28px;
        padding: 10px;
        padding-top:20px
    }
    .footer__whatsApp p {
        font-size: 24px;
        padding:8px;
        letter-spacing: 1px;
    }
    .footer__whatsApp img{
        margin-top:15px;
        width: 120px;
        padding: 5px;
    }
    .footer__address h3{
        font-size: 15px;
        padding: 15px;
    }
}
@media screen and (max-width: 376px){
    .footer__address h3{
        font-size: 12px;
    }
}
@media screen and (max-width: 350px){
    .footer__whatsApp p{
        font-size: 20px;
    }
}